<template>
    <div class="">
        <Loader v-if="loading || modalLoading" />
        <!-- Modal -->
        <!-- 1 - Edit Project Name -->
        <b-modal id="modal-edit-project" @hide="hideProjectModal">
        <b-form-input
            autofocus
            :value="$store.getters.projectName"
            @input="(v) => setNameofProject(v)"
            :state="project_name === '' ? null : nameState"
            aria-describedby="project-name-help project-name-feedback"
            placeholder="Enter a Project Name"
        />
        <b-form-invalid-feedback id="project-name-feedback">
            Min. 5 and Max. 50 characters. Alphanumberics, special symbols are allowed, no leading/trailing spaces.
        </b-form-invalid-feedback>
        <b-form-valid-feedback id="project-name-help">
            Looks good!
        </b-form-valid-feedback>

        <template #modal-footer>
            <div class="w-100 text-center">
                <b-button
                    pill
                    class="px-3 py-2"
                    variant="outline-primary"
                    size="sm"
                    :disabled="!nameState"
                    @click="saveProject"
                >
                    Save
                </b-button>
                <b-button
                    pill
                    class="px-3 py-2"
                    variant="outline-primary"
                    size="sm"
                    @click="hideProjectModal"
                >
                    Cancel
                </b-button>
            </div>
        </template>
        </b-modal>

        <!-- 2 - Select Project Category -->
        <b-modal
            id="modal-edit-project-category"
            :title="getModalTitle()"
            v-model="addSelectionModal"
            size="lg"
            @hide="hideSelectionModal"
        >
            <div v-for="(item, i) in collection" :key="item._id" class="min-h-200">
                <b-row>
                <!-- Life Cycle Stage -->
                    <b-col
                        :xl="collection.length > 1 ? 3 : 4"
                        :md="collection.length > 1 ? 3 : 4"
                    >
                        <b-dropdown
                            :text="item.selected_lcs"
                            :variant="
                                item.selected_lcs == 'Select Life Cycle Stage'
                                ? 'light'
                                : 'primary'
                            "
                            class="m-2"
                            :options="lc_stages"
                        >
                            <b-dropdown-item
                                v-for="data in lc_stages"
                                :key="data"
                                @click="collection[i].selected_lcs = data; getEmissionCategoryTypes(data, i)"
                            >
                                {{ data }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <!-- Emission Category -->
                    <b-col
                        :xl="collection.length > 1 ? 3 : 4"
                        :md="collection.length > 1 ? 3 : 4"
                    >
                        <b-dropdown
                            :disabled="item.selected_lcs == 'Select Life Cycle Stage'"
                            :text="item.selected_ef_type"
                            :variant="
                                item.selected_ef_type == 'Select Emission Category'
                                ? 'light'
                                : 'primary'
                            "
                            class="m-2"
                        >
                            <b-dropdown-item
                                v-for="option of ef_types"
                                :key="option"
                                :value="option"
                                @click="collection[i].selected_ef_type = option"
                            >
                                {{ option }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <!-- Activity Group -->
                    <b-col
                        :xl="collection.length > 1 ? 3 : 4"
                        :md="collection.length > 1 ? 3 : 4"
                    >
                        <b-dropdown
                            :text="item.selected_ag"
                            :disabled="item.selected_ef_type == 'Select Emission Category'"
                            :variant="
                                item.selected_ag == 'Select Activity Group'
                                ? 'light'
                                : 'primary'
                            "
                            class="m-2"
                        >
                            <b-dropdown-item
                                v-for="option of activity_groups"
                                :key="option"
                                :value="option"
                                @click="
                                    // (collection[i].selected_ag = option),
                                    (getAllActivitySubGroupsAndActivityTypes(option, i)),
                                    (item.accordionShow=true)
                                "
                            >
                                {{ option }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="item.activity_groups.length == 0"
                                value=""
                                :disabled="true"
                            />
                            <!--  No activity groups under {{ item.selected_ef_type }}
                            </b-dropdown-item-->
                        </b-dropdown>
                    </b-col>
                    <b-col
                        v-if="collection.length > 1"
                        :xl="3"
                        :md="3"
                        class="accord-position"
                    >
                        <b-row>
                            <!-- Accordion -->
                            <b-button @click="item.accordionShow = !item.accordionShow">
                                <b-icon
                                    :icon="item.accordionShow ? 'chevron-down' : 'chevron-up'"
                                    aria-hidden="true"
                                />
                            </b-button>
                            <!-- Delete Collection -->
                            <b-button
                                @click="collection.splice(i, 1), (item.accordionShow = false)"
                            >
                                <b-icon icon="dash-circle" aria-hidden="true" />
                            </b-button>
                        </b-row>
                    </b-col>
                </b-row>

                <b-collapse :visible="item.accordionShow">
                <b-row>
                    <div class="step-3">
                        <!-- Activity Sub-Group -->
                        <div class="select-activities sub-group">
                            <div class="selected-sub-group">

                                <li
                                    v-for="option of item.selected_sub_ag_n_act_typ"
                                    :key="option"
                                    @click="
                                        () => (
                                            (item.selected_sub_ag_n_act_typ =
                                                item.selected_sub_ag_n_act_typ.filter(
                                                    (val) =>
                                                    val.activity_sub_group !=
                                                    option.activity_sub_group
                                                )
                                            ),
                                            handle_Sel_Act_SG(
                                                item.selected_sub_ag_n_act_typ,
                                                i,
                                                true
                                            )
                                        )
                                    "
                                >
                                    <div>{{ option.activity_sub_group }}</div>
                                    <span>X</span>
                                </li>
                            </div>
                            <!-- :options="$store.getters.allActivitySubGroups" -->
                            <!-- :options="collection[i]['sub_ag_n_act_typ']" -->
                            <!-- :options='["Solid_Waste_Disposal", "Wastewater_Treatment_and_Discharge"]' -->
                            <!-- :options='getSubAGandAG(i)' -->
                            <!-- :options="collection[i]['selected_sub_ag_n_act_typ']" -->
                            <!-- :options="allActivitySubGroups" -->
                            <!-- :options="collection[i]['all_sub_ag_n_act_typ']" -->
                            <multiselect
                                v-model="item.selected_sub_ag_n_act_typ"
                                label="activity_sub_group"
                                track-by="activity_sub_group"
                                :multiple="true"
                                :options="collection[i]['all_sub_ag_n_act_typ']"
                                @input="(val) => handle_Sel_Act_SG(val, i, false)"
                                placeholder=""
                                class="mul-select"
                                openDirection="below"
                            >
                                <template slot="selection" slot-scope="{ values, isOpen }">
                                    <span class v-if="values.length"></span>
                                    <span class v-if="!isOpen">
                                        Select more Activity Sub-Groups
                                    </span>
                                </template>
                            </multiselect>
                        </div>
                        <!-- Activity Types -->
                        <div class="select-activities types">
                            <div class="selected-sub-group">
                                <div
                                    v-for="option of item.selected_activity_types"
                                    :key="option.name"
                                >
                                    <!-- @click="option.selected = !option.selected" -->
                                    <!-- @click="handle_selected_activity_type(option, i)" -->
                                    <li :class="option.selected ? 'activity-type blue' : 'activity-type'"
                                        @click="
                                            // option.selected = !option.selected,
                                            handle_selected_activity_type(option, i)
                                        "
                                    >
                                        {{ getInitials(option.name) }}
                                    </li>
                                    <span>{{ option.name }}</span>
                                </div>
                            </div>
                            <!-- :options="all_activity_types" -->
                            <!-- :options="collection[i]['sub_ag_n_act_typ']['activity_type']" -->
                            <!-- :options="getActivityTypes(i)" -->
                            <multiselect
                                :value="item.selected_activity_types"
                                @input="(val) => handle_Sel_Act_Type(val, i)"
                                v-model="item.selected_activity_types"
                                label="name"
                                track-by="name"
                                :multiple="true"
                                :options="collection[i]['selected_activity_types']"
                                placeholder=""
                                class="mul-select"
                                openDirection="below"
                            >
                                <template slot="selection" slot-scope="{ values, isOpen }">
                                    <span class v-if="values.length"></span>
                                    <span class v-if="!isOpen">
                                        Select more Activity Types
                                    </span>
                                </template>
                            </multiselect>
                            <div class="w-100 text-center">
                                <b-button
                                    pill
                                    class="m-2"
                                    variant="outline-primary"
                                    size="sm"
                                    @click="selectActivityTypes(item)"
                                    type="button"
                                >
                                    Select All Activity Types
                                </b-button>
                                <b-button
                                    pill
                                    class="m-2"
                                    variant="outline-primary"
                                    size="sm"
                                    @click="deselectActivityTypes(item)"
                                    type="button"
                                >
                                    Deselect All Activity Types
                                </b-button>
                            </div>
                        </div>
                    </div>
                </b-row>
                </b-collapse>
            </div>

            <template #modal-footer>
                <div class="w-100 text-center">
                    <b-button
                        v-if="addSelectionModal"
                        pill
                        class="px-3 py-2"
                        variant="outline-primary"
                        size="sm"
                        @click="SubmitSelections()"
                        :disabled="!disableNext"
                    >
                        Submit
                    </b-button>
                    <b-button
                        pill
                        v-if="addSelectionModal"
                        class="outline-px-3 py-2"
                        variant="outline-primary"
                        size="sm"
                        @click="addNewCollection()"
                        :disabled="!disableNext && collection.length != 0"
                    >
                        Add another Life Cycle Stage
                    </b-button>
                    <b-button
                        pill
                        class="px-3 py-2"
                        variant="outline-primary"
                        size="sm"
                        @click="hideSelectionModal"
                    >
                        Cancel
                    </b-button>
                </div>
            </template>
        </b-modal>
        <!-- Modal End -->
    </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import Vue from "vue";
import Loader from "../components/Loader";
// import axios from "../utility/axios";
import Swal from "sweetalert2";

Vue.component("multiselect", Multiselect);
export default {
    components: { Multiselect, Loader },
    name: "EditSelectionModal",
    props: {
        display: {
            type: String,
        },
        openModal: {
            type: Function,
        },
    },
    computed: {
        nameState() {
            var pattern = /^[.a-zA-Z0-9 ]{5,50}$/;
            return pattern.test(this.project_name);
        },
    },
    data() {
        return {
            modalOpen: false,
            addSelectionModal: false,
            loading: false,
            modalLoading: false,
            project_name: "",
            project_id: "",
            initialID: [],
            disableNext: false,
            validCollection: false,
            collection: [],
            sub_ag_n_act_typ: [],
            addCollection: {
                accordionShow: false,
                activity_groups: [],
                sub_ag_n_act_typ: [],
                activity_types: [],
                selected_lcs: "Select Life Cycle Stage",
                selected_ef_type: "Select Emission Category",
                selected_ag: "Select Activity Group",
                selected_sub_ag_n_act_typ: [],
                all_sub_ag_n_act_typ: [],
                selected_activity_types: []
            },
            lc_stages: [],
            ef_types: [],
            activity_groups: [],
            all_activity_sub_groups: [],
            all_activity_types: [],
            life_cycle_stages: [
                {
                    value: "LC1 Material Acquisition",
                    text: "LC1 Material Acquisition",
                }, {
                    value: "LC2 Production",
                    text: "LC2 Production",
                }, {
                    value: "LC3 Distribution & Storage",
                    text: "LC3 Distribution & Storage",
                }, {
                    value: "LC4 Use",
                    text: "LC4 Use",
                }, {
                    value: "LC5 End-of-Life",
                    text: "LC5 End-of-Life",
                },
            ],
        };
    },
    methods: {
        setNameofProject(val) {
            this.project_name = val;
        },
        getModalTitle() {
            if(this.efdb_name)
                return this.project_name + " (EFDB: " + this.efdb_name + ")";
            return this.project_name;
        },
        hideProjectModal() {
            this.$root.$emit("bv::hide::modal", "modal-edit-project");
        },
        showSelectionModal() {
            this.$root.$emit("bv::show::modal", "modal-edit-project-category");
        },
        async hideSelectionModal() {
            this.$root.$emit("bv::hide::modal", "modal-edit-project-category");
            let activitySubGroups = [...this.$store.getters.allActivitySubGroups];
            for (let subGroup of activitySubGroups) {
                for (let type of subGroup.activity_type) {
                    type.selected = true;
                }
            }
            // await this.$store.commit("setAllActivitySubGroups", activitySubGroups);
            console.log("hideSelectionModal", this.collection);
        },
        reset() {
            this.collection = [];
        },
        //   onChange Selected Activity Sub Group
        handle_Sel_Act_SG(val, i) {
            console.log("handle_Sel_Act_SG()", val);
            this.collection[i].selected_sub_ag_n_act_typ = val;

            // Removing duplicates in activity_type_list
            // Keeping activity_type that are selected
            let activity_type_list = [];
            let selected_at_list = [];
            let unselected_at_list = [];
            for(let asg of this.collection[i].selected_sub_ag_n_act_typ) {
                let at_list = asg["activity_type"];
                for(let at_dict of at_list) {
                    if(at_dict["selected"] == true) {
                        selected_at_list.push(at_dict["name"])
                    } else {
                        unselected_at_list.push(at_dict["name"])
                    }
                }
            }
            let selected_at_list2 = [...new Set(selected_at_list)];
            let unselected_at_list2 = [...new Set(unselected_at_list)];
            let unselected_at_list3 = unselected_at_list2.filter(n => !selected_at_list2.includes(n))
            for(let item of selected_at_list2) {
                let tdict = {};
                tdict["name"] = item;
                tdict["selected"] = true;
                activity_type_list.push(tdict)
            }
            for(let item of unselected_at_list3) {
                let tdict = {};
                tdict["name"] = item;
                tdict["selected"] = false;
                activity_type_list.push(tdict)
            }

            console.log("activity_type_list", activity_type_list);
            this.collection[i].selected_activity_types = activity_type_list;

            // let selected_activity_types = [];
            // for(let activity_type_dict of val) {
            //     selected_activity_types.push(...activity_type_dict["activity_type"]);
            // }
            // selected_activity_types = selected_activity_types.filter(
            //     (el) => el.selected
            // );
            // this.collection[i].selected_activity_types = [];
            // let unq_arr = val
            //     .map((x) => x.activity_type)
            //     .concat()
            //     .flat();

            // let result = unq_arr.filter(function (a) {
            //     var key = a.name + "|" + a.selected;
            //     if (!this[key]) {
            //         this[key] = true;
            //         return true;
            //     }
            // }, Object.create(null));
            // for (let name of result) {
            //     this.collection[i].selected_activity_types = this.addType(
            //         selected_activity_types,
            //         name
            //     );
            // }
        },
        handle_selected_activity_type(option, i) {
            console.log("handle_selected_activity_type()");
            // console.log(option);
            // console.log(i);
            // console.log(this.collection[i]['selected_sub_ag_n_act_typ']);
            // console.log(this.collection[i]['selected_activity_types']);

            let curr_flag = option.selected;
            let updated_flag = !curr_flag

            let x = 0;
            for(let item of this.collection[i]['selected_sub_ag_n_act_typ']) {
                let tat = item["activity_type"];
                let y = 0;
                for(let item2 of tat) {
                    if(option.name == item2.name) {
                        console.log("this.collection[i]['selected_sub_ag_n_act_typ'][x]['activity_type'][y]['selected']",
                            this.collection[i]['selected_sub_ag_n_act_typ'][x]['activity_type'][y]['selected'])
                        this.collection[i]['selected_sub_ag_n_act_typ'][x]['activity_type'][y]['selected'] = updated_flag;
                        break;
                    }
                    y += 1;
                }
                x += 1;
            }

            // let z = 0;
            // for(let at of this.collection[i]['selected_activity_types']) {
            //     if(option.name == at.name) {
            //         console.log("this.collection[i]['selected_activity_types'][z]['selected']",
            //             this.collection[i]['selected_activity_types'][z]['selected'])
            //         this.collection[i]['selected_activity_types'][z]['selected'] = updated_flag;
            //         option.selected = updated_flag;
            //         break;
            //     }
            //     z += 1;
            // }

            // console.log(this.collection[i]['selected_sub_ag_n_act_typ']);
            // console.log(this.collection[i]['selected_activity_types']);
            // console.log(option);
            option.selected = updated_flag;
        },
        handle_Sel_Act_Type(val, i) {
            console.log("handle_Sel_Act_Type()", val, i);
            let newVal = val.map((el) => {
                let obj = {};
                obj.name = el.name;
                obj.selected = el.selected;
                return obj;
            });
            this.collection[i].selected_activity_types = newVal;
        },
        addType(arr, name) {
            const found = arr.some(
                (el) =>
                el.name === name.name ||
                (el.name === name.name && el.selected === name.selected)
            );
            if (!found) arr.push(name);
                return arr;
        },
        addNewCollection() {
            this.collection.push({ ...this.addCollection });
        },

        async saveProject() {
            let PrevProjectName = this.$store.getters.projectName;
            let ProjectID = this.$store.getters.projectId;
            if (this.project_name != PrevProjectName) {
                let postData = {
                    project_id: ProjectID,
                    project_name: this.project_name,
                };
                this.axios
                    .post("/editProject", postData)
                    .then((res) => {
                        // console.log(res);
                        this.$store.commit("setProject", res.data.updateProject);
                        this.$root.$emit("setProjectDetails", res.data.updateProject);
                        this.hideProjectModal();
                    })
                    .catch((err) => {
                        Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: err.message,
                        });
                    });
                console.log("postData Name", postData);
            }
        },
        // *** Submit All Edited Selections in a Project ***
        async SubmitSelections() {
            this.loading = true;
            let ProjectID = this.$store.getters.projectId;
            let Promises = [];
            let currentID = [];
            for (let obj of this.collection) {
                let postData = {};
                if (obj._id != undefined) {
                    // Edit Selection
                    currentID.push(obj._id);
                    postData = await this.addSelection(obj, ProjectID);
                    postData.project_selections_id = obj._id;
                    Promises.push(this.axios.post("/editSelection", postData));
                    console.log("postData Edit", postData, obj._id);
                } else {
                    // Add Selection
                    postData = await this.addSelection(obj, ProjectID);
                    Promises.push(this.axios.post("/addSelection", postData));
                    console.log("postData Add", postData, obj._id);
                }
            }
            // Delete Selection
            for (let id of this.initialID) {
                if (!currentID.includes(id)) {
                    let postData = {
                        selection_id: id,
                    };
                    Promises.push(this.axios.post("/deleteSelection", postData));
                    console.log("postData Delete", postData);
                }
            }
            console.log("postData Promises", Promises);
            Promise.all(Promises)
                .then((res) => {
                    console.log("SubmitSelections res", res);
                    this.$root.$emit("getTableData");
                    this.hideSelectionModal();
                    this.loading = false;
                })
                .catch((error) => {
                    console.log("SubmitSelections error", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                    this.loading = false;
                });
        },
        addSelection(obj, ProjectID) {
            let data = {
                project_id: ProjectID,
                lifecycle_stage: obj.selected_lcs,
                emission_category: obj.selected_ef_type,
                activity_group: obj.selected_ag,
                activity_subgroup_list: obj.selected_sub_ag_n_act_typ
                    .map((x) => x.activity_sub_group)
                    .concat()
                    .flat(),
                activity_type_list: this.getSelectedSubActTyp(
                    obj.selected_activity_types
                ),
            };
            return data;
        },
        getSelectedSubActTyp(val) {
            let data = [];
            for (let x of val) {
                if (x.selected) {
                    data.push(x.name);
                }
            }
            return data;
        },
        //  Enable 'Next' button once Data is Validated
        disableNextbtn() {
            let valid = true;
            if (this.collection.length != 0) {
                for (let obj of this.collection) {
                valid =
                    obj.selected_lcs != "Select Life Cycle Stage" &&
                    obj.selected_ef_type != "Select Emission Category" &&
                    obj.selected_ag != "Select Activity Group" &&
                    obj.selected_activity_types.some((obj) => {
                        // console.log({ obj });
                        return obj.selected;
                    }) &&
                    valid;
                }
            } else {
                valid = false;
            }
            this.disableNext = valid;
            console.log("disable :: ", valid);
        },
        removeActivitySubGroup(index, i) {
            this.collection[i].selected_sub_ag_n_act_typ.splice(index, 1);
        },
        getInitials(type) {
            return type.substring(0, 2);
        },
        async getLifecycleStages() {
            this.loading = true;
            await this.axios
                .get("/getLcStages")
                .then((res) => {
                    console.log("getLcStages res", res);
                    this.lc_stages = res.data.data;
                    this.loading = false;
                    console.log("res",res.data.data)
                })
                .catch((error) => {
                    console.log("geLcStages error", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                    this.loading = false;
                });
        },
        async getEmissionCategoryTypes(selected_lifecycle_stage, i) {
            this.loading = true;
            this.axios
                .get("/getEfTypes?lifecycle_stage=" + encodeURIComponent(selected_lifecycle_stage))
                .then((res) => {
                    console.log("getEfTypes res", res);

                    this.collection[i].selected_ef_type = "Select Emission Category";
                    this.ef_types = res.data.data;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log("getEfTypes error", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                    this.loading = false;
                });
        },
        getActivityGroups() {
            this.loading = true;
            this.axios
                .get("/getActivityGroups")
                .then((res) => {
                    this.loading = false;

                    let val = res.data.data;
                    this.activity_groups = val;
                })
                .catch((error) => {
                    console.log("getActivityGroups error", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                    this.loading = false;
                });
        },
        getAllActivitySubGroupsAndActivityTypes(activity_group, i) {
            this.loading = true;
            this.axios
                .get("/getAllActivitySubGroupsAndActivityTypes?activity_group="+encodeURIComponent(activity_group)+"&project_id="+this.$store.getters.projectId)
                .then((res) => {
                    console.log("getAllActivitySubGroupsAndActivityTypes  res", res);
                    console.log("getAllActivitySubGroupsAndActivityTypes() selected_activity_types", this.collection[i].selected_activity_types);

                    let selected_sub_ag_n_act_typ = res.data.data;
                    this.collection[i].all_sub_ag_n_act_typ = res.data.data;

                    if(this.collection[i].selected_ag != activity_group) {
                        this.collection[i].selected_ag = activity_group;
                        this.collection[i].selected_sub_ag_n_act_typ = selected_sub_ag_n_act_typ;
                    } else {
                        let tlist1 = this.collection[i].selected_sub_ag_n_act_typ
                            .map((x) => x.activity_sub_group)
                            .concat();
                        let tlist2 = selected_sub_ag_n_act_typ
                            .map((x) => x.activity_sub_group)
                            .concat();

                        console.log("tlist1", tlist1);
                        console.log("tlist2", tlist2);
                        let new_asg_list = tlist2.filter(n => !tlist1.includes(n))
                        console.log("new_asg_list", new_asg_list);

                        for(let item of selected_sub_ag_n_act_typ) {
                            console.log(item);
                            console.log(item['activity_sub_group']);

                            if(new_asg_list.includes(item['activity_sub_group'])) {
                                console.log("item['activity_sub_group'] in new_asg_list");

                                this.collection[i].selected_sub_ag_n_act_typ.push(item);
                                // this.collection[i].all_sub_ag_n_act_typ = this.collection[i].selected_sub_ag_n_act_typ;
                            }
                        }
                        console.log("this.collection[i].selected_sub_ag_n_act_typ", this.collection[i].selected_sub_ag_n_act_typ);
                    }

                    // Removing duplicates in activity_type_list
                    // Keeping activity_type that are selected
                    let activity_type_list = [];
                    let selected_at_list = [];
                    let unselected_at_list = [];
                    for(let asg of this.collection[i].selected_sub_ag_n_act_typ) {
                        let at_list = asg["activity_type"];
                        for(let at_dict of at_list) {
                            if(at_dict["selected"] == true) {
                                selected_at_list.push(at_dict["name"])
                            } else {
                                unselected_at_list.push(at_dict["name"])
                            }
                        }
                    }
                    let selected_at_list2 = [...new Set(selected_at_list)];
                    let unselected_at_list2 = [...new Set(unselected_at_list)];
                    let unselected_at_list3 = unselected_at_list2.filter(n => !selected_at_list2.includes(n))
                    for(let item of selected_at_list2) {
                        let tdict = {};
                        tdict["name"] = item;
                        tdict["selected"] = true;
                        activity_type_list.push(tdict)
                    }
                    for(let item of unselected_at_list3) {
                        let tdict = {};
                        tdict["name"] = item;
                        tdict["selected"] = false;
                        activity_type_list.push(tdict)
                    }

                    console.log("activity_type_list", activity_type_list);
                    this.collection[i].selected_activity_types = activity_type_list;

                    this.loading = false;
                })
                .catch((error) => {
                    console.log("getAllActivitySubGroupsAndActivityTypes error", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                    this.loading = false;
                });
        },
        //async initActivityGroups(ef_type) {
        // async initActivityGroups() {
        //     this.loading = true;
        //     let val = [];
        //     await this.axios
        //         //.get("/getActivityGroups?ef_type=" + ef_type)
        //         .get("/getActivityGroups")
        //         .then((res) => {
        //             console.log("initActivityGroups res", res);
        //             this.loading = false;
        //             val = res.data.data;
        //         })
        //         .catch((error) => {
        //             console.log("initActivityGroups error", error);
        //             Swal.fire({
        //                 icon: "error",
        //                 title: "Error",
        //                 text: error.message,
        //             });
        //             this.loading = false;
        //         });
        //     return val;
        // },
        // async initAllActivitySubGroupsAndActivityTypes(
        //     ef_type,
        //     selActSubGrp,
        //     selActTyp
        // ) {
        //     this.loading = true;
        //     let val = {};
        //     await this.axios
        //         .get("/getAllActivitySubGroupsAndActivityTypes?ef_type=" + ef_type)
        //         .then((res) => {
        //             console.log("initAllActivitySubGroupsAndActivityTypes  res", res);
        //             let newRes = res.data.data;
        //             this.sub_ag_n_act_typ = newRes;
        //             val.sub_ag_n_act_typ = newRes;
        //             val.selected_sub_ag_n_act_typ = newRes.filter((x) =>
        //                 selActSubGrp.includes(x.activity_sub_group)
        //             );
        //             // set Act type true if it's name exist from prev data
        //             let actType = [];
        //             for (let x of this.all_activity_types) {
        //                 if (selActTyp.includes(x.name)) {
        //                     x.selected = true;
        //                     actType.push(x);
        //                 }
        //             }
        //             val.selected_activity_types = actType;
        //             this.loading = false;
        //         })
        //         .catch((error) => {
        //             console.log("initAllActivitySubGroupsAndActivityTypes error", error);
        //             Swal.fire({
        //                 icon: "error",
        //                 title: "Error",
        //                 text: error.message,
        //             });
        //             this.loading = false;
        //         });
        //     return val;
        // },
        // getAllActivityTypes() {
        //     this.loading = true;
        //     this.axios
        //         .get("/getAllActivityTypes")
        //         .then((res) => {
        //             console.log("getAllActivityTypes res", res);
        //             let data = res.data.data;
        //             let all_activity_types_temp = [];
        //             for (let types of data) {
        //                 let obj = {
        //                     name: types,
        //                     selected: true,
        //                 };
        //                 all_activity_types_temp.push(obj);
        //             }
        //             this.all_activity_types = [...all_activity_types_temp];
        //             this.loading = false;
        //         })
        //         .catch((error) => {
        //             console.log("getAllActivityTypes error", error);
        //             Swal.fire({
        //                 icon: "error",
        //                 title: "Error",
        //                 text: error.message,
        //             });
        //             this.loading = false;
        //         });
        // },
        selectActivityTypes(item) {
            for(let option of item.selected_activity_types) {
                option.selected = true;
            }
        },
        deselectActivityTypes(item) {
            for(let option of item.selected_activity_types) {
                option.selected = false;
            }
        },
        async getSelection() {
            this.loading = true;
            this.axios
                .get("/getSelection?project_id=" + (await this.$store.getters.projectId))
                .then(async (res) => {
                    console.log("getSelection res", res.data.selection);
                    let selection = [...res.data.selection];
                    this.initialID = selection.map((x) => x._id);
                    this.project_name = await this.$store.getters.projectName;

                    // set selection data
                    let collection = [];
                    for (let data of selection) {
                        let obj = { ...this.addCollection };
                        obj.accordionShow = true;

                        // Creating a list of objects containing all Activity Sub-groups and associated Activity Types
                        let allActivitySubGroups = [
                            ...this.$store.getters.allActivitySubGroups,
                        ];
                        // console.log("allActivitySubGroups", allActivitySubGroups);

                        // Subset of allActivitySubGroups, list of objects containing Activity Sub-groups and 
                        // associated Activity Types under selected Activity Type
                        let ActSubGrp = await allActivitySubGroups.filter(
                            (x) => data.activity_subgroup_list.includes(x.activity_sub_group)
                        );
                        console.log("ActSubGrp", ActSubGrp, allActivitySubGroups, data, data.activity_type_list);

                        // set Act type true if it's name exist from prev data
                        let actType = []; // stores activity_type dict
                        let selected_act = []; // stores selected unique activity type names
                        let unselected_act = []; // stores unselected unique activity type names
                        for (let x of ActSubGrp) {
                            for (let y of x.activity_type) {
                                if(data.activity_type_list.includes(y.name)) {
                                    if(!selected_act.includes(y.name)) {
                                        y.selected = true;
                                        actType.push(y);
                                        selected_act.push(y.name);
                                    }
                                } else {
                                    if(!unselected_act.includes(y.name)) {
                                        y.selected = false;
                                        actType.push(y);
                                        unselected_act.push(y.name);
                                    }
                                }
                            }
                        }
                        console.log("actType", actType);

                        obj.selected_lcs = data.lifecycle_stage;
                        obj.selected_ef_type = data.emission_category;
                        obj.selected_ag = data.activity_group;

                        obj.selected_sub_ag_n_act_typ = [...ActSubGrp];
                        obj.all_sub_ag_n_act_typ = [...ActSubGrp];

                        obj.selected_activity_types = [...actType]; // update selected_activity_types

                        obj._id = data._id;
                        collection.push({ ...obj });
                    }
                    this.collection = [...collection];
                    await this.disableNextbtn();
                    this.loading = false;
                })
                .catch((error) => {
                    console.log("getSelection error", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                    this.loading = false;
                });
        },
        async getAllActivitySubGroups() {
            // if (!this.$store.getters.allActivitySubGroups) {
            this.loading = true;
            await this.axios
                .get("/getAllActivitySubGroups?project_id="+this.$store.getters.projectId)
                .then(async (res) => {
                    console.log("getAllActivitySubGroups  res", res);
                    let val = res.data.data;
                    await this.$store.commit("setAllActivitySubGroups", val);
                    this.all_activity_sub_groups = val;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log("getAllActivitySubGroups error", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                    this.loading = false;
                });
            // }
        },
        // getActivityTypes(i) {
        //     let activity_type_list = [];
        //     for(let asg of this.collection[i]['selected_sub_ag_n_act_typ']) {
        //         activity_type_list.push(...asg["activity_type"]);
        //     }

        //     activity_type_list = JSON.parse(JSON.stringify(activity_type_list));
        //     for(let activity_type of activity_type_list) {
        //         activity_type["selected"] = false;
        //     }

        //     return activity_type_list;
        // }
        async getProjectData() {
            let response = await this.axios.get("/getProjectData?project_id=" + (await this.$store.getters.projectId));
            this.efdb_log_id = response.data.data.efdb_log_id;
            this.efdb_name = response.data.data.efdb_name;
        }
    },
    mounted: async function () {
        // Callback to set Project Details
        this.$root.$on("bv::modal::show", async (_, modalId) => {
            if (modalId == "modal-edit-project-category") {
                this.modalLoading = true;

                await this.getProjectData();

                await this.getLifecycleStages();
                await this.getActivityGroups();
                await this.getAllActivitySubGroups();

                // await this.getAllActivityTypes();
                //await this.getEmissionCategoryTypes();

                await this.getSelection();

                this.modalLoading = false;
            }
        });
        this.$root.$on("bv::modal::shown", () => {
            let ele = document.querySelector(
                "#modal-edit-project-category___BV_modal_header_ button.close"
            )
            if(ele)
                ele.setAttribute("tabindex", -1);
        });
        this.$root.$on("setProjectDetails", async (project) => {
            this.project_id = await project._id;
            this.project_name = await project.project_name;
        });
    },
    watch: {
        collection: {
            handler: function (val, oldVal) {
                console.log("col new:", val, oldVal);
                this.disableNextbtn();
            },
            deep: true,
        },
    },
};
</script>

<style scoped lang="scss">
.min-h-200 {
    min-height: 120px !important;
}

.h-100-cust {
    height: 100vh;
}

.float {
    position: fixed;
    bottom: 70px;
    right: 20px;
}

.card {
    height: 159px;
    width: 191px;
    margin: auto;
}

.proj-card {
    .card-body {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }
    .card-title {
        font-size: 25px !important;
        margin-bottom: 0px !important;
        color: black;
    }
}

.cust-border {
    border-color: black !important;
    border-radius: 0px !important;
}

.step-3 {
    margin: 30px;
    min-height: 300px;
    height: 100%;
    width: 100%;
}

.title {
    font-weight: 400;
    font-size: 20px;
}

.select-activities {
    min-height: 250px;
    border-color: black;
    border: 1px solid #707070;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
}
.selected-sub-group {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    li {
        border: 1px solid #707070;
        border-radius: 5px;
        margin: 5px;
        padding: 0px 10px;
        color: #007cba;
        span {
            margin-left: 10px;
            color: #676161;
            cursor: pointer;
        }
    }
}
.select-activities.types {
    border-top: 0px;
    .selected-sub-group {
        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 5.3em;
            margin: 10px 0;
            li.activity-type {
                border: 1px solid #707070;
                width: 72px;
                height: 76px;
                border-radius: 50%;
                margin: 0 auto;
                padding: 0px 10px;
                color: #676161;
                font-size: 2.4em;
                text-align: center;
                cursor: pointer;
            }
            span {
                margin-left: 10px;
                color: #676161;
                // cursor: pointer;
                font-size: 12px;
                text-align: center;

                display: block;
                word-wrap:break-word;
                width: 72px;
                white-space: normal;
            }
        }
    }
}
.accord-position {
    left: 45px;
    top: 7px;
}
</style>

<style lang="scss">
.dropdown-menu.show {
    max-height: 98vh !important;
    overflow-y: auto;
}
.modal-content {
    border-radius: 31px !important;
}
.btn.dropdown-toggle.btn-primary {
    min-width: 250px !important;
    max-width: 250px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.btn.dropdown-toggle.btn-light {
    min-width: 250px !important;
}
.modal-lg {
    max-width: 1050px !important;
}
.modal-title {
    margin-left: auto;
    font-size: 20px;
    color: black;
    font-weight: 400;
}
.blue {
    background-color: #b0e1e9; //original
}
.btn-secondary:focus,
.btn-secondary.focus {
    color: rgb(0, 0, 0);
    background-color: #ffffff;
    border-color: #ffffff;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>